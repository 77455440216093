import { CoreLayout, MenuProps } from 'core-ui';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { lodash } from 'core-helpers';
import Header from './components/Header';
import Logo from './components/Logo';
import { useSelector } from 'react-redux';
import { Role } from 'src/models/role';
import { RoutePath } from 'src/router/routePath';
import { MenuSystemAdmin } from 'src/layouts/AdminLayout/menus/menuSystemAdmin';
import { MenuAdmin } from 'src/layouts/AdminLayout/menus/menuAdmin';
import { MenuClient } from 'src/layouts/AdminLayout/menus/menuClient';
import { MenuPartner } from 'src/layouts/AdminLayout/menus/menuPartner';
import IconDashboard from 'src/assets/icons/SidebarIcons/dashboard.svg';
import { RoleType } from 'src/enums/RoleType';
import './style.scss';

const AdminLayout = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const parentPathname =
    '/' +
    location?.pathname
      .split('/')
      .filter((item, index) => index === 1)
      .join('');

  const [menuSelected, setMenuSelected] = useState(parentPathname);

  const userRole: Role | any = useSelector(
    (state: any) => state.auth.user.role,
  );

  const menuAuthMiddleware = (item: any) => {
    const middleware = item?.middleware;

    let checkAuthMiddleware = false;

    if (middleware.length === 0) {
      checkAuthMiddleware = true;
    } else {
      middleware.forEach((role: any) => {
        if (!lodash.isEmpty(role)) {
          if (
            !(userRole && userRole.role_type && userRole.role_type !== role)
          ) {
            checkAuthMiddleware = true;
          }
          if (!userRole) {
            checkAuthMiddleware = false;
          }
        }
      });
    }

    const icon = item?.icon ? (
      <img src={item?.icon} alt="sidebar-icon" width="24" height="24" />
    ) : (
      ''
    );

    if (checkAuthMiddleware) {
      return {
        key: item?.key,
        icon: icon,
        label: item?.label,
        children: item?.children,
        disabled: item?.disabled,
      };
    }

    return { key: '' };
  };

  const MenuByRole = () => {
    switch (userRole?.role_type) {
      case RoleType.SYSTEM_ADMIN:
        return MenuSystemAdmin;
      case RoleType.ADMIN:
        return MenuAdmin;
      case RoleType.CLIENT:
        return MenuClient;
      case RoleType.PARTNER:
        return MenuPartner;
      default:
        return [];
    }
  };

  const menu: MenuProps['items'] = [
    {
      menu: 'dashboard',
      key: RoutePath.DASHBOARD,
      label: 'ダッシュボード',
      icon: IconDashboard,
      middleware: [],
    },
    ...MenuByRole(),
  ]
    .map((item: any) => menuAuthMiddleware(item))
    .filter((item: any) => item.key !== '');

  useEffect(() => {
    const result = location?.pathname?.split('/').slice(0, 3).join('/');

    setMenuSelected(result);
  }, [menuSelected, location]);

  return (
    <CoreLayout
      headerContent={<Header />}
      logoContent={<Logo />}
      logoClassName="flex items-center pl-6 !bg-white border-b border-[#00000026]"
      menuItems={menu}
      menuProps={{
        selectedKeys: [menuSelected],
        style: {
          borderTop: '2px solid #00a0da',
          background:
            'linear-gradient(0.61deg, #13ae67 -14.56%, #00a0da 102.01%)',
          height: 'calc(-56px + 100vh)',
          borderRight: 0,
          overflowY: 'auto',
        },
        onSelect: (key: any) => {
          setMenuSelected(key?.key);
        },
        onClick: (key: any) => {
          navigate(`${key.key}`);
        },
      }}
      headerStyle={{
        background: 'white',
      }}
      siderStyle={{
        boxShadow: 'none',
        overflowX: 'hidden', //Hide collapse button
      }}
      contentStyle={{
        overflow: 'auto',
      }}
      contentClassName="!bg-[#f2f2f2]"
      contentWrapperClassName="!p-0 "
    >
      <Outlet />
    </CoreLayout>
  );
};

export default AdminLayout;
