import { Dropdown, Space, MenuProps } from 'core-ui';
import iconDownOutlined from 'src/assets/icons/downoutlined.svg';
import { logout } from 'src/services/auth';
import { User } from 'src/models/user';
import { useSelector } from 'react-redux';

const items: MenuProps['items'] = [
  {
    label: (
      <a
        href="/"
        onClick={() => logout()}
        className="flex !text-[#EA3D3D] font-normal text-sm"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33333 5.33332V3.99999C9.33333 3.64637 9.19286 3.30723 8.94281 3.05718C8.69276 2.80713 8.35362 2.66666 8 2.66666H3.33333C2.97971 2.66666 2.64057 2.80713 2.39052 3.05718C2.14048 3.30723 2 3.64637 2 3.99999V12C2 12.3536 2.14048 12.6928 2.39052 12.9428C2.64057 13.1928 2.97971 13.3333 3.33333 13.3333H8C8.35362 13.3333 8.69276 13.1928 8.94281 12.9428C9.19286 12.6928 9.33333 12.3536 9.33333 12V10.6667"
            stroke="#EA3D3D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 8H14M14 8L12 6M14 8L12 10"
            stroke="#EA3D3D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="pl-1">ログアウト</span>
      </a>
    ),
    key: '0',
  },
];

const Header = () => {
  const user: User = useSelector((state: any) => state.auth.user);

  return (
    <div className="h-[100%] flex items-center justify-end pr-[50px] border-b border-[#00000026]">
      <div className="flex items-center">
        <div className="flex items-center gap-1">
          <Dropdown
            arrow={{ pointAtCenter: true }}
            placement="bottom"
            className="flex menu-logout"
            menu={{ items }}
            trigger={['click']}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <div className="flex flex-col">
                  <div className="leading-[20px] font-bold !text-[#191919] text-right">
                    {user.name ?? ''}
                  </div>
                  <div className="leading-[12px] text-[11px] mt-[2px] font-normal !text-[#191919]">
                    {user.company_name ?? ''}
                  </div>
                </div>
                <img
                  src={iconDownOutlined}
                  style={{ filter: 'brightness(0.2)' }}
                  alt="icon-down"
                />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
