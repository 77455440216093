import Cookies from 'js-cookie';
import {
  LoginFormI,
  RegisterFormI,
  ResetPasswordI,
  UpdatePasswordI,
} from 'src/models/auth';
import { apiRequest } from 'src/utils/api';
import { jwtDecode } from 'jwt-decode';
import { timestampToDate } from 'src/utils/date';
import { RoutePath } from 'src/router/routePath';
import { ApiInfo } from 'src/enums/apiInfo';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import { store } from 'src/redux/store';
import { fetchInit } from 'src/services/init';

export const authIs = (role: string) => {
  // system_admin: 1, admin: 2, client: 3, company: 4
  const auth: any = store.getState().auth;

  return role === auth?.user?.role?.role_type;
};

export let isLogged = !!Cookies.get(ACCESS_TOKEN_KEY);

export const login = async (payload: LoginFormI) => {
  const res = await apiRequest.post({
    url: ApiInfo.LOGIN,
    data: payload,
  });

  const accessToken = res?.data?.data;
  const decoded = jwtDecode(accessToken);
  const expiredDate = timestampToDate(Number(decoded?.exp));

  fetchInit().then();

  if (!!accessToken) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
      expires: expiredDate,
    });

    isLogged = true;
  }

  return res;
};

export const register = async (payload: RegisterFormI) => {
  return await apiRequest.post({
    url: ApiInfo.REGISTER,
    data: payload,
  });
};

export const logout = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  window.location.replace(RoutePath.LOGIN);
};

export const resetPassword = async (data: ResetPasswordI) => {
  return apiRequest.post({
    url: ApiInfo.RESET_PASSWORD,
    data,
  });
};

export const updatePassword = async (data: UpdatePasswordI) => {
  return apiRequest.put({
    url: ApiInfo.UPDATE_PASSWORD,
    data,
  });
};
