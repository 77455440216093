import { RoutePath } from 'src/router/routePath';
import { RoleType } from 'src/enums/RoleType';
import IconEstimate from 'src/assets/icons/SidebarIcons/estimate.svg';
import IconSystemAdminUsers from 'src/assets/icons/SidebarIcons/user.svg';
import IconBilling from 'src/assets/icons/SidebarIcons/billing.svg';
import IconProject from 'src/assets/icons/SidebarIcons/project.svg';
import IconContract from 'src/assets/icons/SidebarIcons/contract.svg';

export const MenuClient = [
  {
    key: RoutePath.CLIENT_PROJECT,
    label: 'プロジェクト管理',
    icon: IconProject,
    middleware: [RoleType.CLIENT],
  },
  {
    key: RoutePath.CLIENT_ESTIMATE,
    label: '見積管理',
    icon: IconEstimate,
    middleware: [RoleType.CLIENT],
  },
  {
    key: RoutePath.CLIENT_BILLING,
    label: '請求管理',
    icon: IconBilling,
    middleware: [RoleType.CLIENT],
  },
  {
    key: RoutePath.CLIENT_CONTRACT,
    label: '契約書管理',
    icon: IconContract,
    middleware: [RoleType.CLIENT],
  },
  {
    key: RoutePath.CLIENT_USER,
    label: 'ユーザー管理',
    icon: IconSystemAdminUsers,
    middleware: [RoleType.CLIENT],
  },
];
