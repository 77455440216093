import { createContext, useCallback, useState } from 'react';
import { LoadingOutlined, Spin } from 'core-ui';
import { Role } from 'src/models/role';
import { useSelector } from 'react-redux';
import { authMiddleware } from 'src/router/midlewareAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/router/routePath';

export const LoadingContext = createContext({
  isLoading: false,
  turnOnLoading: () => {},
  turnOffLoading: () => {},
});

const LoadingProvider = ({ children }: { children: React.ReactElement }) => {
  const [isLoading, setIsLoading] = useState(false);

  const turnOnLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const turnOffLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const authRole: Role | any = useSelector(
    (state: any) => state.auth.user.role,
  );
  const location = useLocation();

  if (!authMiddleware(authRole, location)) {
    return <Navigate to={RoutePath.DASHBOARD} replace />;
  }

  return (
    <LoadingContext.Provider
      value={{ turnOnLoading, turnOffLoading, isLoading }}
    >
      {isLoading && (
        <div className="fixed top-0 left-0 w-[100vw] h-[100vh] z-50 bg-[rgba(0,0,0,0.4)] flex justify-center items-center">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 50, color: 'white' }} spin />
            }
          />
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
