import MainLayout from 'src/layouts/MainLayout';
import AdminLayout from 'src/layouts/AdminLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/router/routePath';

const TodoPage = import('../pages/Todo');
const LoginPage = import('../pages/Auth/Login');
const ForgotPasswordPage = import('../pages/Auth/ForgotPassword');
const ResetPasswordPage = import('../pages/Auth/ResetPassword');
const DashboardPage = import('../pages/Commons/Dashboard');
const ClientListingPage = import(
  '../pages/AdminManagement/Client/ClientListing'
);
const ClientCRUPage = import('../pages/AdminManagement/Client/ClientCRU');
const AccountSystem = import('../pages/AccountManagement/AccountSystem');
const AccountAdmin = import('../pages/AccountManagement/AccountAdmin');
const AccountSettingNew = import(
  '../pages/AccountManagement/AccountSettingNew'
);
const OperationPage = import('../pages/Operation');
const AdminPartnerPage = import('../pages/AdminManagement/Partner');
const AdminCreatePartnerPage = import(
  '../pages/AdminManagement/Partner/CreatePartner'
);
const AdminProjectPage = import('../pages/AdminManagement/Project');
const AdminOrderPage = import('../pages/AdminManagement/Order');
const AdminOrderedPage = import('../pages/AdminManagement/Ordered');
const AdminCreateOrderPage = import(
  '../pages/AdminManagement/Order/CreateOrder'
);
const AdminCreateProjectPage = import(
  '../pages/AdminManagement/Project/CreateProject'
);
const AdminUserPage = import('../pages/AdminManagement/User');
const AdminEstimateListingPage = import(
  '../pages/AdminManagement/Estimate/EstimateListing'
);
const AdminEstimateCRUPage = import(
  '../pages/AdminManagement/Estimate/EstimateCRU'
);
const AdminEstimatePartnerListingPage = import(
  '../pages/AdminManagement/EstimatePartner/EstimateListing'
);
const AdminEstimatePartnerCRUPage = import(
  '../pages/AdminManagement/EstimatePartner/EstimateCRU'
);
const BillingListingPage = import(
  '../pages/AdminManagement/Billing/BillingListing'
);
const BillingCRUPage = import('../pages/AdminManagement/Billing/BillingCRU');

const AdminPartnerBillingListingPage = import(
  '../pages/AdminManagement/BillingPartner/BillingListing'
);

const AdminPartnerBillingDetailPage = import(
  '../pages/AdminManagement/BillingPartner/BillingCRU'
);

const AdminContractPage = import('../pages/AdminManagement/Contract');

const AdminContractPartnerPage = import(
  '../pages/AdminManagement/ContractPartner'
);
const ClientUserPage = import('../pages/ClientManagement/User');
const ClientEstimateListingPage = import(
  '../pages/ClientManagement/Estimate/EstimateListing'
);
const ClientEstimateDetailPage = import(
  '../pages/ClientManagement/Estimate/EstimateCRU'
);
const ClientBillingListingPage = import(
  '../pages/ClientManagement/Billing/BillingListing'
);
const ClientBillingDetailPage = import(
  '../pages/ClientManagement/Billing/BillingCRU'
);
const ClientProjectPage = import('../pages/ClientManagement/Project');
const ClientProjectDetailPage = import(
  '../pages/ClientManagement/Project/ProjectDetail'
);
const ClientContractPage = import('../pages/ClientManagement/Contract');
const PartnerUserPage = import('../pages/PartnerManagement/User');
const PartnerOrderPage = import(
  '../pages/PartnerManagement/Order/OrderListing'
);
const PartnerOrderCRUPage = import('../pages/PartnerManagement/Order/OrderCRU');
const PartnerProjectListingPage = import(
  '../pages/PartnerManagement/Project/ProjectListing'
);
const PartnerProjectDetailPage = import(
  '../pages/PartnerManagement/Project/ProjectDetail'
);
const PartnerEstimateListingPage = import(
  '../pages/PartnerManagement/Estimate/EstimateListing'
);
const PartnerEstimateCreatePage = import(
  '../pages/PartnerManagement/Estimate/EstimateCRU'
);
const PartnerEstimateEditPage = import(
  '../pages/PartnerManagement/Estimate/EstimateCRU'
);
const PartnerEstimateDetailPage = import(
  '../pages/PartnerManagement/Estimate/EstimateCRU'
);
const PartnerContractPage = import('../pages/PartnerManagement/Contract');
const PartnerBillingCRUPage = import(
  '../pages/PartnerManagement/Billing/BillingCRU'
);
const PartnerBillingListingPage = import(
  '../pages/PartnerManagement/Billing/BillingListing'
);

export const routes: RouteI[] = [
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage),
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    Component: lazy(() => ForgotPasswordPage),
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage),
  },
  {
    path: RoutePath.DASHBOARD,
    Component: lazy(() => DashboardPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CLIENT,
    Component: lazy(() => ClientListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CLIENT_CREATE,
    Component: lazy(() => ClientCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CLIENT_EDIT,
    Component: lazy(() => ClientCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CLIENT_DETAIL,
    Component: lazy(() => ClientCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ACCOUNT_SYSTEM,
    Component: lazy(() => AccountSystem),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ACCOUNT_SETTING_NEWS,
    Component: lazy(() => AccountSettingNew),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ACCOUNT_ADMIN,
    Component: lazy(() => AccountAdmin),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.OPERATION,
    Component: lazy(() => OperationPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_PARTNER,
    Component: lazy(() => AdminPartnerPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CREATE_PARTNER,
    Component: lazy(() => AdminCreatePartnerPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_EDIT_PARTNER,
    Component: lazy(() => AdminCreatePartnerPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_PROJECT,
    Component: lazy(() => AdminProjectPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CREATE_PROJECT,
    Component: lazy(() => AdminCreateProjectPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_EDIT_PROJECT,
    Component: lazy(() => AdminCreateProjectPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_DETAIL_PROJECT,
    Component: lazy(() => AdminCreateProjectPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_EDIT_PARTNER,
    Component: lazy(() => AdminCreatePartnerPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_DETAIL_PARTNER,
    Component: lazy(() => AdminCreatePartnerPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ESTIMATE,
    Component: lazy(() => AdminEstimateListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ESTIMATE_CREATE,
    Component: lazy(() => AdminEstimateCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ESTIMATE_EDIT,
    Component: lazy(() => AdminEstimateCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ESTIMATE_DETAIL,
    Component: lazy(() => AdminEstimateCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ESTIMATE_PARTNER,
    Component: lazy(() => AdminEstimatePartnerListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ESTIMATE_PARTNER_DETAIL,
    Component: lazy(() => AdminEstimatePartnerCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_BILLING,
    Component: lazy(() => BillingListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_BILLING_CREATE,
    Component: lazy(() => BillingCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_BILLING_EDIT,
    Component: lazy(() => BillingCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_BILLING_DETAIL,
    Component: lazy(() => BillingCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_BILLING_PARTNER,
    Component: lazy(() => AdminPartnerBillingListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_BILLING_PARTNER_DETAIL,
    Component: lazy(() => AdminPartnerBillingDetailPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ORDER,
    Component: lazy(() => AdminOrderPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CREATE_ORDER,
    Component: lazy(() => AdminCreateOrderPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_EDIT_ORDER,
    Component: lazy(() => AdminCreateOrderPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_DETAIL_ORDER,
    Component: lazy(() => AdminCreateOrderPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CONTRACT,
    Component: lazy(() => AdminContractPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_CONTRACT_PARTNER,
    Component: lazy(() => AdminContractPartnerPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_USER,
    Component: lazy(() => AdminUserPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.ADMIN_ORDERED,
    Component: lazy(() => AdminOrderedPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_ESTIMATE,
    Component: lazy(() => ClientEstimateListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_ESTIMATE_DETAIL,
    Component: lazy(() => ClientEstimateDetailPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_BILLING,
    Component: lazy(() => ClientBillingListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_BILLING_DETAIL,
    Component: lazy(() => ClientBillingDetailPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_PROJECT,
    Component: lazy(() => ClientProjectPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_PROJECT_DETAIL,
    Component: lazy(() => ClientProjectDetailPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_CONTRACT,
    Component: lazy(() => ClientContractPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.CLIENT_USER,
    Component: lazy(() => ClientUserPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_USER,
    Component: lazy(() => PartnerUserPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_ORDER,
    Component: lazy(() => PartnerOrderPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_ORDER_DETAIL,
    Component: lazy(() => PartnerOrderCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_PROJECT,
    Component: lazy(() => PartnerProjectListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_PROJECT_DETAIL,
    Component: lazy(() => PartnerProjectDetailPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_ESTIMATE,
    Component: lazy(() => PartnerEstimateListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_ESTIMATE_CREATE,
    Component: lazy(() => PartnerEstimateCreatePage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_ESTIMATE_EDIT,
    Component: lazy(() => PartnerEstimateEditPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_ESTIMATE_DETAIL,
    Component: lazy(() => PartnerEstimateDetailPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_BILLING,
    Component: lazy(() => PartnerBillingListingPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_BILLING_CREATE,
    Component: lazy(() => PartnerBillingCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_BILLING_EDIT,
    Component: lazy(() => PartnerBillingCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_BILLING_DETAIL,
    Component: lazy(() => PartnerBillingCRUPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.PARTNER_CONTRACT,
    Component: lazy(() => PartnerContractPage),
    Layout: <AdminLayout />,
    isPrivate: true,
  },
  {
    path: RoutePath.TODO,
    Component: lazy(() => TodoPage),
    Layout: <MainLayout />,
    isPrivate: true,
  },
];
