import { combineReducers } from '@reduxjs/toolkit';
import authInfoSlice from 'src/redux/authInfoSlice';
import clientSlice from 'src/redux/clientSlice';

const rootReducer = combineReducers({
  auth: authInfoSlice.reducer,
  client: clientSlice.reducer,
});

export default rootReducer;
