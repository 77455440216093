import iconHeaderDashboard from 'src/assets/icons/ic_header_custom.svg';

const Logo = () => {
  return (
    <div className="logo-image">
      <img
        src="/img_logo_header.png"
        className="h-[40px] w-auto"
        width="673"
        height="153"
        alt="img_logo_header.png"
      />
    </div>
  );
};

export default Logo;
