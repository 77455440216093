import { RoutePath } from 'src/router/routePath';
import { RoleType } from 'src/enums/RoleType';
import IconClient from 'src/assets/icons/SidebarIcons/client.svg';
import IconPartner from 'src/assets/icons/SidebarIcons/partner.svg';
import IconProject from 'src/assets/icons/SidebarIcons/project.svg';
import IconEstimate from 'src/assets/icons/SidebarIcons/estimate.svg';
import IconOrder from 'src/assets/icons/SidebarIcons/order.svg';
import IconOrdered from 'src/assets/icons/SidebarIcons/ordered.svg';
import IconBilling from 'src/assets/icons/SidebarIcons/billing.svg';
import IconContract from 'src/assets/icons/SidebarIcons/contract.svg';
import IconSystemAdminUsers from 'src/assets/icons/SidebarIcons/user.svg';

export const MenuAdmin = [
  {
    key: RoutePath.ADMIN_CLIENT,
    label: '顧客管理',
    icon: IconClient,
    middleware: [RoleType.ADMIN],
  },
  {
    key: RoutePath.ADMIN_PARTNER,
    label: '発注会社管理',
    icon: IconPartner,
    middleware: [RoleType.ADMIN],
  },
  {
    key: RoutePath.ADMIN_PROJECT,
    label: 'プロジェクト管理',
    icon: IconProject,
    middleware: [RoleType.ADMIN],
  },
  {
    key: RoutePath.ADMIN_ESTIMATE,
    label: '見積管理',
    icon: IconEstimate,
    middleware: [RoleType.ADMIN],
    children: [
      {
        key: RoutePath.ADMIN_ESTIMATE,
        label: '顧客見積',
      },
      {
        key: RoutePath.ADMIN_ESTIMATE_PARTNER,
        label: '発注会社見積',
      },
    ],
  },
  {
    key: RoutePath.ADMIN_ORDER,
    label: '発注管理',
    icon: IconOrder,
    middleware: [RoleType.ADMIN],
  },
  {
    key: RoutePath.ADMIN_ORDERED,
    label: '受注管理',
    icon: IconOrdered,
    middleware: [RoleType.ADMIN],
  },
  {
    key: RoutePath.ADMIN_BILLING,
    label: '請求管理',
    icon: IconBilling,
    middleware: [RoleType.ADMIN],
    children: [
      {
        key: RoutePath.ADMIN_BILLING,
        label: '顧客会社請求',
      },
      {
        key: RoutePath.ADMIN_BILLING_PARTNER,
        label: '発注会社請求',
      },
    ],
  },
  {
    key: RoutePath.ADMIN_CONTRACT,
    label: '契約書管理',
    icon: IconContract,
    middleware: [RoleType.ADMIN],
    children: [
      {
        key: RoutePath.ADMIN_CONTRACT,
        label: '顧客会社',
      },
      {
        key: RoutePath.ADMIN_CONTRACT_PARTNER,
        label: '発注会社',
      },
    ],
  },
  {
    key: RoutePath.ADMIN_USER,
    label: 'ユーザー管理',
    icon: IconSystemAdminUsers,
    middleware: [RoleType.ADMIN],
  },
];
