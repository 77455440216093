import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import LoadingProvider from './contexts/loadingContext';
import { ConfigProvider, ja_JP } from 'core-ui';

const queryClient = new QueryClient();

const App = () => {
  return (
    <ConfigProvider
      locale={ja_JP}
      theme={{
        components: {
          Input: {
            controlHeight: 40,
          },
          Select: {
            controlHeight: 40,
          },
        },
      }}
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <LoadingProvider>
              <ModalProvider>
                <DrawerProvider>
                  <AppRouter />
                </DrawerProvider>
              </ModalProvider>
            </LoadingProvider>
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
