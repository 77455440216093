import { ApiInfo } from 'src/enums/apiInfo';
import { apiRequest } from 'src/utils/api';
import authInfoSlice from 'src/redux/authInfoSlice';
import { store } from 'src/redux/store';
import { logout } from 'src/services/auth';

const url = ApiInfo.ME;

export const getMe = async () => {
  const res = await apiRequest.get({
    url: url,
  });

  return res.data;
};

export const fetchInit = async () => {
  try {
    const { setAuthInfo } = authInfoSlice.actions;
    //set Auth
    const res = await getMe();
    const data = res.data;
    store.dispatch(setAuthInfo(data));
  } catch (err) {
    console.log(err);
    logout();
  }
};
