import { CoreLayout, MenuProps, UnorderedListOutlined } from 'core-ui';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Logo from './components/Logo';

const MainLayout = () => {
  const menuItems: MenuProps['items'] = [
    {
      key: 'todo',
      icon: React.createElement(UnorderedListOutlined),
      label: 'Todo',
    },
  ];

  return (
    <CoreLayout
      headerContent={<Header />}
      logoContent={<Logo />}
      logoClassName="flex justify-center items-center"
      menuItems={menuItems}
      siderStyle={{
        overflowX: 'hidden',
      }}
      contentStyle={{
        overflow: 'auto',
      }}
    >
      <Outlet />
    </CoreLayout>
  );
};

export default MainLayout;
