import { RoutePath } from 'src/router/routePath';
import { RoleType } from 'src/enums/RoleType';
import IconAdminUsers from 'src/assets/icons/SidebarIcons/users.svg';
import IconSystemAdminUsers from 'src/assets/icons/SidebarIcons/user.svg';
import IconSettingNew from 'src/assets/icons/SidebarIcons/ic_setting_new.svg';

export const MenuSystemAdmin = [
  {
    key: RoutePath.ACCOUNT_ADMIN,
    label: '契約会社管理',
    icon: IconAdminUsers,
    middleware: [RoleType.SYSTEM_ADMIN],
  },
  {
    key: RoutePath.ACCOUNT_SYSTEM,
    label: 'システムユーザー管理',
    icon: IconSystemAdminUsers,
    middleware: [RoleType.SYSTEM_ADMIN],
  },
  {
    key: RoutePath.ACCOUNT_SETTING_NEWS,
    label: '通知設定',
    icon: IconSettingNew,
    middleware: [RoleType.SYSTEM_ADMIN],
  },
];
