import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'client',
  initialState: {
    client: [],
  },
  reducers: {
    setListClient: (state, action) => {
      state.client = action.payload;
    },
  },
});
