export const API_VERSION = process.env.REACT_APP_VERSION;

export const API_URL = `https://${process.env.REACT_APP_API_URL}`;

export const API_ADDRESS = `${API_URL}/api/${API_VERSION}`;

export enum ApiInfo {
  LOGIN = '/login',
  REGISTER = '/register',
  TODO = '/todos',
  RESET_PASSWORD = '/reset_password',
  UPDATE_PASSWORD = '/update_password',
  ME = '/me',
  ACCOUNT_ADMIN = '/system_admin/admin',
  ACCOUNT_SYSTEM = '/system_admin/system_users',
  ACCOUNT_SETTING_NEWS = '/system_admin/news',
  PARTNER = '/admin/companies',
  CLIENT = '/admin/clients',
  PROJECT = '/admin/projects',
  ESTIMATE = '/admin/estimates',
  ESTIMATE_PARTNER = '/admin/estimates/created_by_partner',
  ORDERS = '/admin/orders',
  ADMIN_BILLING = '/admin/bills',
  ADMIN_PARTNER_BILL = '/admin/partner_bills',
  ADMIN_PROJECT_PAYMENT_PLAN = '/admin/project_payment_plans',
  ORDERED = '/admin/ordereds',
  ADMIN_USER = '/admin/admin_users',
  ADMIN_CONTRACT = '/admin/contracts',
  CLIENT_USER = '/client/users',
  CLIENT_ESTIMATE = '/client/estimates',
  CLIENT_BILLING = '/client/bills',
  CLIENT_PROJECT = '/client/projects',
  CLIENT_CONTRACT = '/client/contracts',
  PARTNER_USER = '/company/company_users',
  PARTNER_CONTRACT = '/company/contracts',
  PARTNER_ORDER = '/company/orders',
  PARTNER_PROJECT = '/company/projects',
  PARTNER_ESTIMATE = '/company/estimates',
  PARTNER_BILL = '/company/bills',
  DASHBOARD_NEWS = '/news',
}
