import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'auth',
  initialState: {
    user: { id: 0 },
  },
  reducers: {
    setAuthInfo: (state, action) => {
      state.user = action.payload;
    },
  },
});
